/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLink } from '../../../hook'
import { withAuthenticableNavigationBlock } from '../../../ui'
import AccountHomeView from './account-home-view'

function AccountHomeController() {
  // prepare hook
  const { navigate } = useLink()

  useEffect(() => {
    navigate('/account/profile/', { replace: true })
  }, [])

  return (
    <AccountHomeView />
  )
}

export default withAuthenticableNavigationBlock(AccountHomeController)
